import { Route, Routes } from "react-router-dom";
import DashboardPanel from "@/modules/DashboardPanel";
import { MainLayout } from "@/modules";
import { MenuProps } from "antd";
import { pages } from "@/root/constants";
import { FcPieChart, FcDocument } from "react-icons/fc";

const AdminPage = () => {
  const navigation: MenuProps["items"] = [
    {
      key: pages.admin.dashboard.path,
      label: "Головна панель",
      icon: <FcPieChart size={23} style={{ minWidth: 23 }} />,
    },
    {
      key: pages.admin.another.path,
      label: "Друга сторінка",
      icon: <FcDocument size={23} style={{ minWidth: 23 }} />,
    },
  ];
  return (
    <MainLayout navigation={navigation}>
      <Routes>
        <Route path="/*">
          <Route path="dashboard/*" element={<DashboardPanel />} />
          <Route path="another/*" element={<span>Друга сторінка</span>} />
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default AdminPage;
