import { store } from "@/store";
import { ReactNode } from "react";
import { Provider } from "react-redux";

interface ReduxProvideProps {
  children: ReactNode;
}

const ReduxProvide = ({ children }: ReduxProvideProps) => {
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvide;
