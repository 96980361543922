import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Suspense } from "react";
import { Loader } from "./components/common";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReduxProvider } from "./components";

import "antd/dist/reset.css";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 20 * 60 } },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ReduxProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Suspense fallback={<Loader center />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </ReduxProvider>
);

reportWebVitals();
