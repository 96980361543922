import AdminPage from "@/pages/AdminPage";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const LoginPage = lazy(() => import("@/pages/LoginPage"));
const ErrorPage = lazy(() => import("@/pages/ErrorPage"));

const Router = () => {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <Suspense fallback={<div />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path="admin/*"
        element={
          <Suspense fallback={<div />}>
            <AdminPage />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<div />}>
            <ErrorPage />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
