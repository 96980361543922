import makeRequest from "@/modules/DataFetch/api/makeRequest";
import { apiBaseurl } from "../../../api/baseurl";
import { User } from "@/root/types/user";

type LoginData = {
  expires: string;
  token: string;
} & User;

export const AuthApi = {
  login: async (login: string, password: string): Promise<LoginData> => {
    const data = await makeRequest<LoginData>({
      method: "post",
      url: apiBaseurl + "/auth/",
      data: {
        login,
        password,
      },
    });

    return data;
  },

  me: async (): Promise<User> => {
    const data = await makeRequest<User>({
      method: "get",
      url: apiBaseurl + "/me/",
    });

    return data;
  },
};
