export const pages = {
  login: {
    path: "/login",
  },
  admin: {
    path: "/admin",
    dashboard: {
      path: "/admin/dashboard",
    },
    another: {
      path: "/admin/another",
    },
  },
};
