import useAuth from "@/modules/Auth/hooks/useAuth";
import Router from "./router";
import { Loader } from "./components/common";

const App = () => {
  const { isReady } = useAuth();

  if (isReady) {
    return <Router />;
  }

  return (
    <div style={{ height: "100vh" }}>
      <Loader center />
    </div>
  );
};

export default App;
