import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  clearAuthToken,
  getAuthToken,
  isTokenExpired,
} from "@/utils/tokenHelpers";
import { me } from "@/store/reducers/user/asyncActionCreators";
import { useAppDispatch, useAppSelector } from "@/store/hooks/redux";
import { LoadingStatus } from "@/root/enums";
import { pages } from "@/root/constants";

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.userReducer.getMeLoading);
  const userRoles = useAppSelector(
    (state) => state.userReducer.user?.userRoles
  );

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) {
      navigate(pages.login.path);
      clearAuthToken();
      setIsReady(true);
    } else {
      const { token } = getAuthToken();
      if (token) {
        dispatch(me());
      }
    }
  }, []);

  useEffect(() => {
    if (loading === LoadingStatus.ERROR) {
      setIsReady(true);
      navigate(pages.login.path);
    } else if (loading === LoadingStatus.SUCCESS) {
      if (!!userRoles?.length) {
        setIsReady(true);
      }
    }
  }, [loading, userRoles]);

  useEffect(() => {
    if (!!userRoles?.length) {
      navigate(pages.admin.dashboard.path);
    }
  }, [userRoles]);

  return { isReady };
};

export default useAuth;
